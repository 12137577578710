/*.loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid rgba(0,0,0,.2 );
    border-radius: 50%;
    border-top-color: rgba(0,0,0,.4 );
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    left: calc(50%);
    top: calc(50%);
    position: fixed;
    z-index: 1;
    }
    */
.loading {
  position: relative;
  width: 80px;
  height: 80px;
  border: none;
  box-shadow: 0 0 0 0 rgba(24, 118, 210, 0.7);
  border-radius: 50%;
  background-image: url(../../assets/img/logo_popser.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  left: calc(50%);
  top: calc(50%);
  position: fixed;
  z-index: 1;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

.loading-screen {
  background-color: #f7f9fc;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.MuiPaper-elevation1 {
  box-shadow: none;
}
